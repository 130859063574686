import React from "react";
// import logo from '../../assets/images/backgrounds/LOGO_PAGE.jpg';
import "./style.scss";
import logo from "../../assets/images/luzverde_logo.png";

const SplasScreen = () => {
	return (
		<>
			<div className="image__container">
				<img src={logo} alt="logo" className="logo__main" />
			</div>
		</>
	);
};

export default SplasScreen;
