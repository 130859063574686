import axios from "axios";

export let headers = {
	"Tenant-Access-Token": "N2JjZmI1YjdjOTU5YjYwZDhkMDhiM2JkZmViMGI1OTg=",
	"Tenant-Access-Key": "n1ldORGfh1XBLA",
};

export const api = axios.create({
	baseURL: "https://tiltlabs-dev.confirmu.com",
	headers: {
		"Tenant-Access-Token": "N2JjZmI1YjdjOTU5YjYwZDhkMDhiM2JkZmViMGI1OTg=",
		"Tenant-Access-Key": "n1ldORGfh1XBLA",
	},
});

export const BASE_URL = "https://tiltlabs-dev.confirmu.com";
