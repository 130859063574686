import React from "react";
import Layout from "../common/layoutred";
import Asher from "../common/asher";
import logo from "../../assets/images/luzverde_logo.png";
import "./style.scss";
import { connect } from "react-redux";
import { setIndex, nextScreen } from "../../store/screentransistion/actions";

const AsherIntro = ({
	asher,
	narration,
	alt,
	setIndex,
	nextScreen,
	currentIndex,
	sceneId,
	tapToContinue,
	narrationAssetUrl,
	playVoiceOver,
	imageAssetUrl,
	isWindowActive,
}) => {
	const handleClick = () => {
		nextScreen(sceneId[currentIndex]);
		setIndex(currentIndex + 1);
	};
	return (
		<div onClick={handleClick}>
			<Layout>
				<div className="asher__intro__logo__container">
					<img src={logo} alt="logo__play_2_score" className="play__logo" />
				</div>
				<Asher
					asher={`${imageAssetUrl}${asher.url}`}
					text={narration.message}
					audioClip={narration.audioClip}
					narrationAssetUrl={narrationAssetUrl}
					playVoiceOver={playVoiceOver}
					alt="asher intro"
					isWindowActive={isWindowActive}
				/>
				<h1 className="asher__tap__to__continue">{tapToContinue.message}</h1>
			</Layout>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		currentIndex: state.screenChange.currentIndex,
		sceneId: state.shuffleData.sceneId,
		narrationAssetUrl: state.shuffleData.narrationDataSet.narrationAssetUrl,
		playVoiceOver: state.screenChange.playVoiceOver,
		imageAssetUrl: state.shuffleData.imageDataSet.imageAssetUrl,
		isWindowActive: state.screenChange.isWindowOpen,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		nextScreen: (id) => dispatch(nextScreen(id)),
		setIndex: (index) => dispatch(setIndex(index)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AsherIntro);
