import { INTIALIZE_SUCCESS, SAVE_LANGUAGES, SAVE_ZONES } from '../types/index';
const intialState = {
  isLoaded: false,
  languages: [],
  zones: [],
};

const intializeReducer = (state = intialState, action) => {
  switch (action.type) {
    case INTIALIZE_SUCCESS:
      return { ...state, isLoaded: action.payload };
    case SAVE_LANGUAGES:
      return { ...state, languages: [...action.payload] };
    case SAVE_ZONES:
      return { ...state, zones: [...action.payload] };
    default:
      return state;
  }
};

export default intializeReducer;
